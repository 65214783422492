<template>

  <div id="learning-category">

    <KnowledgeBase class="mb-10"/>

    <IssueTracking :item="item"/>

  </div>

</template>

<script>
import vSelect from 'vue-select'
import moment from "moment";
import axios from '@/axios';

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

import KnowledgeBase from './KnowledgeBaseTable'
import IssueTracking from './IssueTrackingTable'


export default {
  components: {
    vSelect,
    KnowledgeBase,
    IssueTracking,

    // Cell Renderer
    CellRendererActions,
  },
  data () {
    return {
      loaded: false,
      form: {},
      popup: false,
      popupDetail: false,
      mode: 'add',
      itemSelected: false,
      item: {},
      moment: moment,
    }
  },
  methods: {
     loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })

    },

    onLoaded () {
      this.$vs.loading.close();
      this.loaded = true;
    },
  },
  created () {
    this.loading();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
