<template>
  <div>
    <h3 class="mb-3">Issue Tracking</h3>
    <!-- Issue Tracking Table -->
    <div class="vx-card p-6">
    
      <!-- Header Action -->
      <div class="flex justify-between flex-wrap items-center">
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />

        <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="addItem">Add Item</vs-button>
      </div>


      <!-- AgGrid Table Issue Tracking -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <!-- Pagination Issue Tracking -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <!-- PAGINATION -->
          <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
      </div>
    </div>

    <!-- Popup Detail -->
    <vs-popup
      classContent="popup-example"
      title="Detail Learning Group"
      :active.sync="popupDetail"
      v-if="item"
    >
      <!-- show detail -->
      <div class="grid grid-cols-2 justify-center">
        <div class="container mb-5">
          <h1 class="text-lg font-semibold mb-2">
            Name
          </h1>
          <span class="block">
            {{ item.name }}
          </span>
        </div>
        <div class="container mb-5">
          <h1 class="text-lg font-semibold mb-2">
            Added At
          </h1>
          <span class="block">
            {{ moment(item.added_at).format('DD-MM-YYYY') }}
          </span>
        </div>
      </div>
    </vs-popup>

     <!--  Popup Form  -->
    <vs-popup classContent="popup-example" :title="`${mode} item`" :active.sync="popup">

      <div class="vx-row">
        <div class="vx-col md:w-1/1 w-full">
          <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="form.name" />
        </div>
      </div>

      <div class="mt-5">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ $i18n.t('Save') }}
        </vs-button>
      </div>

    </vs-popup>
  </div>
  
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import moment from "moment";
import axios from '@/axios';

// Cell Renderer
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererActions,
  },
  data () {
    return {
      loaded: false,
      searchQuery: '',
      form: {},
      popup: false,
      popupDetail: false,
      mode: 'add',
      itemSelected: false,
      item: {},
      moment: moment,

      // AgGrid
      gridApi: null,
      gridOptions: {},
      itemsData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Name'),
          field: 'name',
          filter: true,
          width: 300,
          wrapText: true,
          autoHeight: true,
        },
        {
          headerName: this.$i18n.t('Total Theories'),
          field: 'theories_count',
          filter: true,
          headerClass: 'text-center',
          cellClass: 'text-center'
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            showItem: this.showItem.bind(this),
            editItem: this.editItem.bind(this),
            deleteItem: this.deleteItem.bind(this),
          },
          headerClass: 'text-center',
          cellClass: 'text-center'
        }
      ],
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },

     loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })

    },

    onLoaded () {
      this.$vs.loading.close();
      this.loaded = true;
    },

    getData () {
      axios.get('/technical/learning-center/learning-category').then(resp => {
        this.onLoaded();
        if(resp.data.data.issue_tracking) {
          this.itemsData = resp.data.data.issue_tracking
        }
      }
      ).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    showItem (item) {
       axios.get('/technical/learning-center/learning-category/' + item.id)
      .then(({data: res}) => {
        this.popupDetail = true
        this.item = res.data
      })
      .catch(err => console.error(err))
    },
    addItem () {
      this.popup = true;
      this.mode = 'add';
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        name: item.name,
      };
    },
    storeItem () {
      if (this.mode == 'add') {
        let body = {
          name: this.form.name,
          for_type: 2
        };

        axios.post('/technical/learning-center/learning-category', body).then(resp => {
          this.popup = false;
          this.$vs.notify({
            color: 'success',
            title: 'Successful',
            text: resp.data.msg_status
          })
          this.getData();
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          name: this.form.name,
          for_type: 2,
          _method: 'PUT',
        };

        axios.post('/technical/learning-center/learning-category/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$vs.notify({
            color: 'success',
            title: 'Successful',
            text: resp.data.msg_status
          })
          this.getData();
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }

      this.mode = false;
    },
    deleteItem (item) {
      axios.delete('/technical/learning-center/learning-category/' + item.id).then(resp => {
        this.$vs.notify({
            color: 'success',
            title: 'Successful',
            text: resp.data.msg_status
          })
        this.getData();
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.msg_status,
          color:'danger'
        })
      })
    },
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit()
  },
  watch: {
    $route () {
      this.getData();
    },
    popup () {
      if (!this.popup) {
        this.form = {};
      }
    }
  },
  created () {
    this.loading();
    this.getData();
  }
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
